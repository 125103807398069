import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Move by Melissa',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION, PRINT DESIGN',
    description: `Designed and developed the Move by Melissa brand for social media influencer Melissa Molinaro. Her online program features live classes for her Power Booty fitness program as well as a user portal where the user can view the content on their mobile devices and stream to their apple TV. We designed and planned her Power Booty Tour fall 2019 which included marketing assets, recipe booklet and branded merchandise. We wanted to develop a brand that was fun, modern and aggresive. Motivating her followers to get up and move. `,
    link: 'movebymelissa.com',
  },
  caseId: 'move',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/LXDIMcjQ-giz7EkeH.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/LXDIMcjQ-1280.jpg',
      },
    },
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
